/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../api'
type GeneratedMutation<InputType, OutputType> = string & {
    __generatedMutationInput: InputType
    __generatedMutationOutput: OutputType
}

export const createEvent = /* GraphQL */ `mutation CreateEvent(
  $input: CreateEventInput!
  $condition: ModelEventConditionInput
) {
  createEvent(input: $input, condition: $condition) {
    eventId
    name
    owner
    description
    eventStartDateTime
    eventEndDateTime
    imageBase64
    authenticationKey
    photos {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateEventMutationVariables,
    APITypes.CreateEventMutation
>
export const updateEvent = /* GraphQL */ `mutation UpdateEvent(
  $input: UpdateEventInput!
  $condition: ModelEventConditionInput
) {
  updateEvent(input: $input, condition: $condition) {
    eventId
    name
    owner
    description
    eventStartDateTime
    eventEndDateTime
    imageBase64
    authenticationKey
    photos {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateEventMutationVariables,
    APITypes.UpdateEventMutation
>
export const deleteEvent = /* GraphQL */ `mutation DeleteEvent(
  $input: DeleteEventInput!
  $condition: ModelEventConditionInput
) {
  deleteEvent(input: $input, condition: $condition) {
    eventId
    name
    owner
    description
    eventStartDateTime
    eventEndDateTime
    imageBase64
    authenticationKey
    photos {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteEventMutationVariables,
    APITypes.DeleteEventMutation
>
export const createEventPhoto = /* GraphQL */ `mutation CreateEventPhoto(
  $input: CreateEventPhotoInput!
  $condition: ModelEventPhotoConditionInput
) {
  createEventPhoto(input: $input, condition: $condition) {
    id
    owner
    event {
      eventId
      name
      owner
      description
      eventStartDateTime
      eventEndDateTime
      imageBase64
      authenticationKey
      createdAt
      updatedAt
      __typename
    }
    eventId
    imagePath
    thumbnailPath
    height
    width
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateEventPhotoMutationVariables,
    APITypes.CreateEventPhotoMutation
>
export const updateEventPhoto = /* GraphQL */ `mutation UpdateEventPhoto(
  $input: UpdateEventPhotoInput!
  $condition: ModelEventPhotoConditionInput
) {
  updateEventPhoto(input: $input, condition: $condition) {
    id
    owner
    event {
      eventId
      name
      owner
      description
      eventStartDateTime
      eventEndDateTime
      imageBase64
      authenticationKey
      createdAt
      updatedAt
      __typename
    }
    eventId
    imagePath
    thumbnailPath
    height
    width
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateEventPhotoMutationVariables,
    APITypes.UpdateEventPhotoMutation
>
export const deleteEventPhoto = /* GraphQL */ `mutation DeleteEventPhoto(
  $input: DeleteEventPhotoInput!
  $condition: ModelEventPhotoConditionInput
) {
  deleteEventPhoto(input: $input, condition: $condition) {
    id
    owner
    event {
      eventId
      name
      owner
      description
      eventStartDateTime
      eventEndDateTime
      imageBase64
      authenticationKey
      createdAt
      updatedAt
      __typename
    }
    eventId
    imagePath
    thumbnailPath
    height
    width
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteEventPhotoMutationVariables,
    APITypes.DeleteEventPhotoMutation
>
