import { Event } from '../api.ts'
import new_event_image from '../assets/new_event_image.jpg'
import * as qrcode from 'qrcode'

export interface IEventModel {
    eventId: string
    name: string
    description: string
    startDateTime: string
    endDateTime: string
    eventImage: string
    status: string
    statusColor: string
    captureUrl: string
    authenticationKey: string
    generateCaptureQRCode(): Promise<string>
}

export class EventModel implements IEventModel {
    public readonly eventId: string
    public name: string
    public description: string
    public startDateTime: string
    public endDateTime: string

    private imageBase64: string
    private _authenticationKey?: string | null
    private generatedCaptureQRCode?: string

    private constructor(
        eventId: string,
        name: string,
        description: string,
        imageBase64: string,
        startDateTime?: string | null,
        endDateTime?: string | null,
        authenticationKey?: string | null
    ) {
        this.eventId = eventId
        this.name = name
        this.description = description
        this.imageBase64 = imageBase64
        this.startDateTime = startDateTime ?? ''
        this.endDateTime = endDateTime ?? ''
        this._authenticationKey = authenticationKey
    }

    public get eventImage(): string {
        if (this.imageBase64) {
            return `data:image/png;base64,${this.imageBase64}`
        }
        return new_event_image
    }

    public get status(): string {
        const dateNow = new Date()

        if (!this.startDateTime || !this.endDateTime) {
            return 'Not Configured'
        }

        if (dateNow < new Date(this.startDateTime)) {
            return 'Upcoming'
        }

        if (dateNow > new Date(this.endDateTime)) {
            return 'Ended'
        }

        return 'In Progress'
    }

    public get statusColor(): string {
        switch (this.status) {
            case 'Upcoming':
                return 'rgba(200,0,255,0.3)'
            case 'In Progress':
                return 'rgba(41,255,0,0.3)'
            case 'Ended':
                return 'rgba(255,0,0,0.3)'
            default:
                return 'rgba(93,93,93,0.3)'
        }
    }

    public get captureUrl(): string {
        return `https://app.snap-pix.co.uk/capture/${this.eventId}?auth=${this.authenticationKey}`
    }

    public get authenticationKey(): string {
        return this._authenticationKey ?? ''
    }

    public generateCaptureQRCode = async () => {
        try {
            if (this.generatedCaptureQRCode) {
                return this.generatedCaptureQRCode
            }

            this.generatedCaptureQRCode = await qrcode.toDataURL(
                this.captureUrl
            )

            return this.generatedCaptureQRCode
        } catch (err) {
            console.error(err)
            return ''
        }
    }

    public static fromDTO(dto: Event): EventModel {
        return new EventModel(
            dto.eventId,
            dto.name,
            dto.description ?? '',
            dto.imageBase64 ?? '',
            dto.eventStartDateTime,
            dto.eventEndDateTime,
            dto.authenticationKey
        )
    }
}
