import * as APITypes from '../api.extend.ts'
type GeneratedQuery<InputType, OutputType> = string & {
    __generatedQueryInput: InputType
    __generatedQueryOutput: OutputType
}

export const iamEventsByAuthenticationKeyAndEventId =
    /* GraphQL */ `query IamEventsByAuthenticationKeyAndEventId(
  $authenticationKey: String!
  $eventId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  eventsByAuthenticationKeyAndEventId(
    authenticationKey: $authenticationKey
    eventId: $eventId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      eventId
      name
      description
      eventStartDateTime
      eventEndDateTime
      imageBase64
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
        APITypes.IamEventsByAuthenticationKeyAndEventIdQueryVariables,
        APITypes.IamEventsByAuthenticationKeyAndEventIdQuery
    >
