import { defineStore } from 'pinia'
import { Event } from '../api.ts'
import { generateClient } from 'aws-amplify/api'
import {
    CaptureEventModel,
    ICaptureEventModel,
} from '../models/CaptureEventModel.ts'
import { iamEventsByAuthenticationKeyAndEventId } from '../graphql/queries.extend.ts'

interface State {
    event?: ICaptureEventModel
    isLoading: boolean
}

const client = generateClient({
    authMode: 'iam',
})

export const useCaptureEventStore = defineStore('CaptureEventStore', {
    state: (): State => ({
        /** @type: ICaptureEventModel[] */
        event: undefined,
        /** @type: boolean */
        isLoading: true,
    }),
    getters: {},
    actions: {
        async fetchEvent(eventId: string, authenticationKey: string) {
            try {
                this.isLoading = true

                const response = await client.graphql({
                    query: iamEventsByAuthenticationKeyAndEventId,
                    variables: {
                        eventId: { eq: eventId },
                        authenticationKey: authenticationKey,
                    },
                })

                const events = response.data.eventsByAuthenticationKeyAndEventId

                if (!events.items) {
                    return undefined
                }

                if (events.items.length === 0) {
                    return undefined
                }

                this.event = CaptureEventModel.fromDTO(events.items[0] as Event)

                console.log(this.event)
            } catch (error) {
                console.error(error)
            } finally {
                this.isLoading = false
            }
        },
    },
})
