/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../api'
type GeneratedQuery<InputType, OutputType> = string & {
    __generatedQueryInput: InputType
    __generatedQueryOutput: OutputType
}

export const getEventPhoto =
    /* GraphQL */ `query GetEventPhoto($id: ID!, $eventId: ID!) {
  getEventPhoto(id: $id, eventId: $eventId) {
    id
    owner
    event {
      eventId
      name
      owner
      description
      eventStartDateTime
      eventEndDateTime
      imageBase64
      authenticationKey
      createdAt
      updatedAt
      __typename
    }
    eventId
    imagePath
    thumbnailPath
    height
    width
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
        APITypes.GetEventPhotoQueryVariables,
        APITypes.GetEventPhotoQuery
    >
export const listEventPhotos = /* GraphQL */ `query ListEventPhotos(
  $id: ID
  $eventId: ModelIDKeyConditionInput
  $filter: ModelEventPhotoFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listEventPhotos(
    id: $id
    eventId: $eventId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      owner
      eventId
      imagePath
      thumbnailPath
      height
      width
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListEventPhotosQueryVariables,
    APITypes.ListEventPhotosQuery
>
export const eventPhotosByEventId = /* GraphQL */ `query EventPhotosByEventId(
  $eventId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelEventPhotoFilterInput
  $limit: Int
  $nextToken: String
) {
  eventPhotosByEventId(
    eventId: $eventId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      eventId
      imagePath
      thumbnailPath
      height
      width
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.EventPhotosByEventIdQueryVariables,
    APITypes.EventPhotosByEventIdQuery
>
export const getEvent = /* GraphQL */ `query GetEvent($eventId: ID!) {
  getEvent(eventId: $eventId) {
    eventId
    name
    owner
    description
    eventStartDateTime
    eventEndDateTime
    imageBase64
    authenticationKey
    photos {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetEventQueryVariables, APITypes.GetEventQuery>
export const listEvents = /* GraphQL */ `query ListEvents(
  $eventId: ID
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listEvents(
    eventId: $eventId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      eventId
      name
      owner
      description
      eventStartDateTime
      eventEndDateTime
      imageBase64
      authenticationKey
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListEventsQueryVariables, APITypes.ListEventsQuery>
export const eventsByAuthenticationKeyAndEventId =
    /* GraphQL */ `query EventsByAuthenticationKeyAndEventId(
  $authenticationKey: String!
  $eventId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  eventsByAuthenticationKeyAndEventId(
    authenticationKey: $authenticationKey
    eventId: $eventId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      eventId
      name
      owner
      description
      eventStartDateTime
      eventEndDateTime
      imageBase64
      authenticationKey
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
        APITypes.EventsByAuthenticationKeyAndEventIdQueryVariables,
        APITypes.EventsByAuthenticationKeyAndEventIdQuery
    >
