import { defineStore } from 'pinia'
import { Event, EventPhoto } from '../api.ts'
import {
    eventPhotosByEventId,
    getEvent,
    listEvents,
} from '../graphql/queries.ts'
import { generateClient } from 'aws-amplify/api'
import { createEvent, deleteEvent, updateEvent } from '../graphql/mutations.ts'
import { v4 as uuidv4 } from 'uuid'
import { EventModel, IEventModel } from '../models/EventModel.ts'
import * as subscriptions from '../graphql/subscriptions'
import { Subscription } from 'rxjs'

interface State {
    events: IEventModel[]
    eventPhotosByEventId: { [key: string]: EventPhoto[] }
    subscriptions: { [key: string]: Subscription }
    isLoading: boolean
    photosLoading: boolean
}

const client = generateClient({
    authMode: 'userPool',
})

export const useEventStore = defineStore('EventStore', {
    state: (): State => ({
        /** @type: EventModel[] */
        events: [],
        eventPhotosByEventId: {},
        subscriptions: {},
        /** @type: boolean */
        isLoading: false,
        /** @type: boolean */
        photosLoading: false,
    }),
    getters: {
        count: (state: State) => state.events.length,
        getEventById: (state: State) => (eventId: string) => {
            return state.events.find((event) => event.eventId === eventId)
        },
        getPhotosByEventId: (state: State) => (eventId: string) => {
            return state.eventPhotosByEventId[eventId]
        },
    },
    actions: {
        async updateEvent(
            eventId: string,
            name: string,
            description: string,
            startDate: string,
            endDate: string,
            authenticationKey: string
        ) {
            const response = await client.graphql({
                query: updateEvent,
                variables: {
                    input: {
                        eventId: eventId,
                        name: name,
                        description: description,
                        eventStartDateTime: startDate,
                        eventEndDateTime: endDate,
                        authenticationKey: authenticationKey,
                    },
                },
            })

            const eventModel = EventModel.fromDTO(
                response.data.updateEvent as Event
            )

            const foundIndex = this.events.findIndex(
                (e) => e.eventId === eventModel.eventId
            )

            if (foundIndex !== -1) {
                this.events[foundIndex] = eventModel
            } else {
                this.events.push(eventModel)
            }

            return eventModel
        },
        async createEvent(name: string, description: string) {
            const result = await client.graphql({
                query: createEvent,
                variables: {
                    input: {
                        eventId: uuidv4().toString(),
                        name: name,
                        description: description,
                    },
                },
            })

            const newEvent = result.data.createEvent as Event

            this.events = [EventModel.fromDTO(newEvent), ...this.events]

            return newEvent
        },
        async fetchEvents() {
            this.isLoading = true

            const result = await client.graphql({ query: listEvents })

            this.events = result.data.listEvents.items
                .sort((a: Event, b: Event) => {
                    return (
                        new Date(b.createdAt).getTime() -
                        new Date(a.createdAt).getTime()
                    )
                })
                .map((x) => EventModel.fromDTO(x))

            this.isLoading = false
        },
        async fetchEvent(id: string): Promise<EventModel | undefined> {
            try {
                this.isLoading = true

                const response = await client.graphql({
                    query: getEvent,
                    variables: { eventId: id },
                })

                console.log(response)

                const eventModel = EventModel.fromDTO(
                    response.data.getEvent as Event
                )

                const foundIndex = this.events.findIndex(
                    (e) => e.eventId === eventModel.eventId
                )

                if (foundIndex !== -1) {
                    this.events[foundIndex] = eventModel
                } else {
                    this.events.push(eventModel)
                }

                return eventModel
            } catch (error) {
                console.error(error)
                return undefined
            } finally {
                this.isLoading = false
            }
        },
        async fetchUpcomingEvents() {
            this.isLoading = true

            try {
                const currentDate = new Date()
                const currentDateISOString = currentDate.toISOString()
                const eventStartDate = new Date(
                    currentDate.setDate(currentDate.getDate() + 7)
                ).toISOString()

                const filter = {
                    eventStartDateTime: {
                        between: [currentDateISOString, eventStartDate],
                    },
                }

                const result = await client.graphql({
                    query: listEvents,
                    variables: { filter },
                })

                this.events = result.data.listEvents.items
                    .sort((a: Event, b: Event) => {
                        return (
                            new Date(b.createdAt).getTime() -
                            new Date(a.createdAt).getTime()
                        )
                    })
                    .map((x: Event) => EventModel.fromDTO(x))
            } catch (error) {
                console.error(error)
            } finally {
                this.isLoading = false
            }
        },
        async fetchRecentlyEndedEvents() {
            this.isLoading = true

            try {
                const currentDate = new Date()
                const currentDateISOString = currentDate.toISOString()
                const eventEndDate = new Date(
                    currentDate.setDate(currentDate.getDate() - 7)
                ).toISOString()

                const filter = {
                    eventEndDateTime: {
                        between: [eventEndDate, currentDateISOString],
                    },
                }

                const result = await client.graphql({
                    query: listEvents,
                    variables: { filter },
                })

                this.events = result.data.listEvents.items
                    .sort((a: Event, b: Event) => {
                        return (
                            new Date(b.createdAt).getTime() -
                            new Date(a.createdAt).getTime()
                        )
                    })
                    .map((x: Event) => EventModel.fromDTO(x))
            } catch (error) {
                console.error(error)
            } finally {
                this.isLoading = false
            }
        },
        async fetchEventPhotos(eventId: string) {
            try {
                this.photosLoading = true

                const response = await client.graphql({
                    query: eventPhotosByEventId,
                    variables: { eventId: eventId },
                })

                console.log(response)

                this.eventPhotosByEventId[eventId] =
                    response.data.eventPhotosByEventId.items.sort(
                        (a: EventPhoto, b: EventPhoto) => {
                            return (
                                new Date(b.createdAt).getTime() -
                                new Date(a.createdAt).getTime()
                            )
                        }
                    )

                console.log(this.eventPhotosByEventId[eventId])

                return this.eventPhotosByEventId[eventId]
            } catch (error) {
                console.error(error)
                return undefined
            } finally {
                this.photosLoading = false
            }
        },
        async deleteEvent(eventId: string) {
            const result = await client.graphql({
                query: deleteEvent,
                variables: {
                    input: {
                        eventId: eventId,
                    },
                },
            })

            if (result.data.deleteEvent) {
                const event = this.events.find((e) => e.eventId === eventId)

                if (!event) {
                    return
                }

                const index = this.events.indexOf(event)

                if (index !== -1) {
                    this.events.splice(index, 1)
                }
            } else {
                console.error('Failed to delete event')
                throw new Error('Failed to delete event')
            }
        },
        subscribeToPhotos(eventId: string) {
            if (this.subscriptions[eventId]) return

            const variables = {
                filter: {
                    eventId: { eq: eventId },
                },
            }

            this.subscriptions[eventId] = client
                .graphql({
                    query: subscriptions.onCreateEventPhoto,
                    variables,
                })
                .subscribe({
                    next: ({ data }) => {
                        const newPhoto = data.onCreateEventPhoto as EventPhoto
                        this.eventPhotosByEventId[eventId].splice(
                            0,
                            0,
                            newPhoto
                        )
                    },
                    error: (error) => console.warn(error),
                })
        },
        unsubscribeToPhotos(eventId: string) {
            if (this.subscriptions[eventId])
                this.subscriptions[eventId].unsubscribe()
        },
    },
})
