<script lang="ts" setup>
import Sidebar from 'primevue/sidebar'
import { useEventStore } from '../../../stores/EventStore.ts'
import { onMounted, onUnmounted, ref, toRef } from 'vue'
import router from '../../../router.ts'
import { EventModel } from '../../../models/EventModel.ts'
import { EventPhoto } from '../../../api.ts'
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'

const confirm = useConfirm()
const toast = useToast()

const props = defineProps({
    eventId: String,
})

const eventStore = useEventStore()
const sidebarVisible = ref(false)

const eventRef = toRef<EventModel | undefined>(undefined)
const qrCodeRef = ref('')

const getCdnUrl = (path: string) => {
    return `https://cdn.harry-hadcroft.app.non-prod.snap-pix.co.uk/${path}`
}

const confirmDelete = () => {
    confirm.require({
        message: 'Do you want to delete this event?',
        header: 'Danger Zone',
        icon: 'pi pi-info-circle',
        rejectLabel: 'Cancel',
        acceptLabel: 'Delete',
        rejectClass: 'p-button-secondary p-button-outlined',
        acceptClass: 'p-button-danger',
        accept: async () => {
            if (!eventRef?.value) {
                toast.add({
                    severity: 'error',
                    summary: 'Error Deleting Event',
                    detail: 'Your event was not deleted',
                    life: 3000,
                })
                return
            }

            try {
                await eventStore.deleteEvent(eventRef.value.eventId)
            } catch (error) {
                toast.add({
                    severity: 'error',
                    summary: 'Error Deleting Event',
                    detail: 'Your event was not deleted',
                    life: 3000,
                })
                return
            }

            await router.push('/events')

            toast.add({
                severity: 'success',
                summary: 'Confirmed',
                detail: 'Event was deleted',
                life: 3000,
            })
        },
        reject: () => {
            toast.add({
                severity: 'error',
                summary: 'Cancelled',
                detail: 'Your event was not deleted',
                life: 3000,
            })
        },
    })
}

const getImageWidth = (image: EventPhoto) => {
    const widthRatio = image.width / image.height

    if (widthRatio > 1.5) {
        return 400
    }

    return 200
}

const getImageHeight = (image: EventPhoto) => {
    const heightRatio = image.height / image.width

    if (heightRatio > 1.5) {
        return 400
    }

    return 200
}

const getImageClass = (image: EventPhoto) => {
    const width = getImageWidth(image)
    const height = getImageHeight(image)

    const widthRatio = width / height
    const heightRatio = height / width

    if (widthRatio > 1.5) {
        return 'col-span-2'
    } else if (heightRatio > 1.5) {
        return 'row-span-2'
    } else {
        return 'col-span-2 row-span-2'
    }
}

const activeIndex = ref(0)
const responsiveOptions = ref([
    {
        breakpoint: '1024px',
        numVisible: 5,
    },
    {
        breakpoint: '768px',
        numVisible: 3,
    },
    {
        breakpoint: '560px',
        numVisible: 1,
    },
])
const displayCustom = ref(false)

const imageClick = (index: number) => {
    activeIndex.value = index
    displayCustom.value = true
}

const downloadQRCode = () => {
    const link = document.createElement('a')
    link.href = qrCodeRef.value
    link.download = `${eventRef.value?.name
        .toLowerCase()
        .replace(' ', '-')}-qr-code.png`
    link.click()
}

onMounted(async () => {
    if (!props.eventId) {
        await router.push('/')
        return
    }

    const event =
        eventStore.getEventById(props.eventId) ??
        (await eventStore.fetchEvent(props.eventId))

    console.log(event)

    if (!event) {
        await router.push('/')
        return
    }

    qrCodeRef.value = await event.generateCaptureQRCode()
    eventRef.value = event

    await eventStore.fetchEventPhotos(props.eventId)
    eventStore.subscribeToPhotos(props.eventId)

    document.title = `Event - ${event.name}`
})

onUnmounted(() => {
    if (props.eventId) {
        eventStore.unsubscribeToPhotos(props.eventId)
    }
})
</script>

<template>
    <div>
        <div
            class=""
            style="
                height: 200px;
                background: url('/cover.jpg') no-repeat;
                background-size: cover;
            "
        ></div>
        <div class="px-2 py-5 md:px-6 lg:px-8">
            <div
                v-if="eventRef"
                class="flex flex-column lg:flex-row lg:align-items-center lg:justify-content-between relative"
                style="margin-top: -2rem; top: -70px; margin-bottom: -70px"
            >
                <Sidebar
                    v-model:visible="sidebarVisible"
                    class="w-full md:w-20rem lg:w-30rem"
                    header="Event Details"
                    position="right"
                >
                    <div class="text-500 mb-3">
                        Here you will find all the details of your event.
                    </div>
                    <div
                        class="grid-custom grid-nogutter border-top-1 surface-border pt-2"
                    >
                        <div class="col-12 py-2 px-3">
                            <div class="text-500 font-medium mb-2">Name</div>
                            <div class="text-900">{{ eventRef.name }}</div>
                        </div>
                        <div class="col-12 py-2 px-3">
                            <div class="text-500 font-medium mb-2">
                                Description
                            </div>
                            <div class="text-900">
                                {{ eventRef.description }}
                            </div>
                        </div>
                        <div class="col-12 py-2 px-3">
                            <div class="text-500 font-medium mb-2">
                                Start Date
                            </div>
                            <div class="text-900">
                                {{
                                    eventRef.startDateTime
                                        ? eventRef.startDateTime
                                        : 'Not set'
                                }}
                            </div>
                        </div>
                        <div class="col-12 py-2 px-3">
                            <div class="text-500 font-medium mb-2">
                                End Date
                            </div>
                            <div class="text-900">
                                {{
                                    eventRef.endDateTime
                                        ? eventRef.endDateTime
                                        : 'Not set'
                                }}
                            </div>
                        </div>
                        <div class="col-12 py-2 px-3">
                            <div class="text-500 font-medium mb-2">
                                Capture Link
                            </div>
                            <div class="text-900">
                                <a
                                    :href="eventRef.captureUrl"
                                    target="_blank"
                                    >{{ eventRef.captureUrl }}</a
                                >
                            </div>
                        </div>
                        <div class="col-12 py-2 px-3">
                            <div class="text-500 font-medium mb-2">QR Code</div>
                            <div class="flex justify-center">
                                <img :src="qrCodeRef" alt="QR Code" />
                            </div>
                        </div>
                        <div class="col-12 py-2 px-3 flex justify-between">
                            <Button
                                class="p-button-outlined mt-2 md:mt-0"
                                icon="pi pi-download"
                                label="Download QR Code"
                                @click="downloadQRCode"
                            ></Button>
                            <Button
                                class="p-button-outlined mt-2 md:mt-0"
                                icon="pi pi-download"
                                label="Download Photos"
                            ></Button>
                        </div>
                        <div class="col-12 py-2 px-3 flex justify-between">
                            <Button
                                class="mt-2 md:mt-0 p-button-danger w-full"
                                icon="pi pi-trash"
                                label="Delete Event"
                                @click="confirmDelete"
                            ></Button>
                        </div>
                    </div>
                </Sidebar>
                <div class="w-full">
                    <div class="mb-3 flex align-items-center justify-between">
                        <div class="relative">
                            <div
                                class="surface-card shadow-2"
                                style="
                                    width: 140px;
                                    height: 140px;
                                    border-radius: 10px;
                                "
                            >
                                <img
                                    :src="eventRef.eventImage"
                                    alt="Image"
                                    height="140"
                                    width="140"
                                />
                            </div>
                            <span
                                :style="{
                                    backgroundColor: eventRef.statusColor,
                                }"
                                class="absolute px-2 py-1 border-round-lg text-sm font-normal text-white mt-0 mb-0 bg-opacity-30"
                                style="
                                    backdrop-filter: blur(10px);
                                    top: 3%;
                                    left: 3%;
                                "
                            >
                                {{ eventRef.status }}
                            </span>
                        </div>
                        <div class="mt-7">
                            <router-link
                                :to="{
                                    name: 'EventEdit',
                                    params: { eventId: eventRef.eventId },
                                }"
                            >
                                <Button
                                    class="mr-2"
                                    icon="pi pi-plus-circle"
                                    label="Edit"
                                ></Button>
                            </router-link>
                            <Button
                                class="p-button-outlined p-button-rounded"
                                icon="pi pi-ellipsis-v"
                                @click="sidebarVisible = true"
                            ></Button>
                        </div>
                    </div>
                    <div class="text-900 text-3xl font-medium mb-3">
                        {{ eventRef.name }}
                    </div>
                    <p class="mt-0 mb-3 text-700 text-xl">
                        {{ eventRef.description }}
                    </p>
                    <div
                        v-if="eventRef.startDateTime && eventRef.endDateTime"
                        class="text-600 font-medium"
                    >
                        <span>
                            Starts: {{ eventRef.startDateTime }} | Ends:
                            {{ eventRef.endDateTime }}
                        </span>
                    </div>
                    <div v-else class="text-600 font-medium">
                        <span> Event dates not set </span>
                    </div>
                    <div class="mt-3">
                        <Galleria
                            v-model:activeIndex="activeIndex"
                            v-model:visible="displayCustom"
                            :circular="true"
                            :fullScreen="true"
                            :numVisible="7"
                            :responsiveOptions="responsiveOptions"
                            :showItemNavigators="true"
                            :showThumbnails="false"
                            :value="
                                eventStore.getPhotosByEventId(eventRef.eventId)
                            "
                            containerStyle="max-width: 850px"
                        >
                            <template #item="slotProps">
                                <img
                                    :src="getCdnUrl(slotProps.item.imagePath)"
                                    alt="Image"
                                    style="width: 65%; display: block"
                                />
                            </template>
                            <template #thumbnail="slotProps">
                                <img
                                    :src="
                                        getCdnUrl(slotProps.item.thumbnailPath)
                                    "
                                    alt="Image"
                                    style="display: block"
                                />
                            </template>
                        </Galleria>
                        <div
                            class="grid-custom grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8"
                            style="background: rgb(235 235 235 / 70%)"
                        >
                            <template
                                v-for="(
                                    image, index
                                ) of eventStore.getPhotosByEventId(
                                    eventRef.eventId
                                )"
                                :key="index"
                            >
                                <DeferredContent :class="getImageClass(image)">
                                    <img
                                        :height="getImageHeight(image)"
                                        :src="getCdnUrl(image.thumbnailPath)"
                                        :width="getImageWidth(image)"
                                        alt="Image"
                                        class="shadow-3 hover:scale-105 transition-transform duration-300 ease-in-out"
                                        style="
                                            cursor: pointer;
                                            object-fit: cover;
                                            width: 100%;
                                            height: 100%;
                                        "
                                        @click="imageClick(index)"
                                    />
                                </DeferredContent>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>
