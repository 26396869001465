<script setup lang="ts">
import { ref, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import Toast from 'primevue/toast'
import InputGroup from 'primevue/inputgroup'
import InputGroupAddon from 'primevue/inputgroupaddon'
import { toTypedSchema } from '@vee-validate/zod'
import * as zod from 'zod'
import { useField, useForm } from 'vee-validate'
import { useEventStore } from '../../../stores/EventStore.ts'

const eventStore = useEventStore()
const toast = useToast()
const successToastVisible = ref(false)
const createEventDialogVisible = ref(false)

const validationSchema = toTypedSchema(
    zod.object({
        name: zod.string().trim().min(3).max(50),
        description: zod.string().trim().min(3).max(250),
    })
)

const { handleSubmit, errors, resetForm } = useForm({
    validationSchema,
})

const { value: name } = useField('name')
const { value: description } = useField('description')

watch(createEventDialogVisible, () => {
    resetForm()
})

const onSubmit = handleSubmit(async ({ name, description }, { resetForm }) => {
    try {
        const newEvent = await eventStore.createEvent(name, description)

        toast.removeGroup('editSuccess')

        toast.add({
            severity: 'success',
            summary: 'Your event has been created!',
            detail: { data: newEvent },
            group: 'editSuccess',
        })

        createEventDialogVisible.value = false
        resetForm()
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Message failed to send!',
            detail: 'The message failed to send. Please try emailing us directly at hello@delmdigital.co.uk',
            life: 3000,
        })
    }
})

const onClose = () => {
    successToastVisible.value = false
}

eventStore.fetchEvents()
</script>

<template>
    <Toast position="bottom-center" group="editSuccess" @close="onClose">
        <template #message="slotProps">
            <div class="flex flex-column align-items-start" style="flex: 1">
                <span class="p-toast-summary flex align-items-center">
                    <span class="pi pi-exclamation-circle pr-1"></span>
                    {{ slotProps.message.summary }}
                </span>
                <div class="flex justify-evenly w-full my-2">
                    <router-link
                        :to="{
                            name: 'EventDetails',
                            params: {
                                eventId: slotProps.message.detail.data.eventId,
                            },
                        }"
                    >
                        <Button
                            v-ripple
                            class="p-button p-button-sm mt-2 ml-2 w-32"
                            label="View"
                        ></Button>
                    </router-link>
                    <router-link
                        :to="{
                            name: 'EventEdit',
                            params: {
                                eventId: slotProps.message.detail.data.eventId,
                            },
                        }"
                    >
                        <Button
                            v-ripple
                            class="p-button p-button-sm mt-2 ml-2 w-32"
                            label="Edit"
                        ></Button>
                    </router-link>
                </div>
            </div>
        </template>
    </Toast>
    <Dialog
        v-model:visible="createEventDialogVisible"
        appendTo="body"
        modal
        :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
        :style="{ width: '52vw' }"
        header="Create New Event"
        :draggable="false"
        :resizable="false"
    >
        <template #header>
            <div class="flex flex-column gap-2">
                <h1 class="m-0 text-900 font-semibold text-xl line-height-3">
                    Create New Event
                </h1>
                <span class="text-600 text-base"
                    >Please enter a name and description for your new
                    event</span
                >
            </div>
        </template>
        <form
            id="newEventForm"
            class="flex flex-column gap-3 mt-3"
            @submit.prevent="onSubmit"
        >
            <div>
                <label for="event-name" class="block mb-1 text-color text-base"
                    >Event Name</label
                >
                <span class="p-input-icon-left w-full">
                    <InputGroup>
                        <InputGroupAddon>
                            <i class="pi pi-user"></i>
                        </InputGroupAddon>
                        <InputText
                            name="event-name"
                            type="text"
                            class="w-full"
                            id="event-name"
                            v-model="name"
                        />
                    </InputGroup>
                    <InlineMessage v-if="errors.name" class="mt-3"
                        >{{ errors.name }}
                    </InlineMessage>
                </span>
            </div>
            <div>
                <label for="description" class="block mb-1 text-color text-base"
                    >Event Description</label
                >
                <span class="p-input-icon-left w-full">
                    <InputGroup>
                        <InputGroupAddon>
                            <i class="pi pi-credit-card"></i>
                        </InputGroupAddon>
                        <InputText
                            name="description"
                            type="text"
                            class="w-full"
                            id="description"
                            v-model="description"
                        />
                    </InputGroup>
                    <InlineMessage v-if="errors.description" class="mt-3"
                        >{{ errors.description }}
                    </InlineMessage>
                </span>
            </div>
        </form>
        <template #footer>
            <div class="flex gap-3 justify-content-end surface-border pt-5">
                <Button
                    label="Cancel"
                    @click="createEventDialogVisible = false"
                    class="p-button-text"
                ></Button>
                <Button
                    form="newEventForm"
                    label="Create"
                    type="submit"
                    class="p-button-rounded"
                ></Button>
            </div>
        </template>
    </Dialog>
    <section class="px-6 py-5">
        <header
            class="flex w-full justify-content-between align-items-center border-bottom-1 surface-border pb-5 flex-column md:flex-row"
        >
            <div>
                <h2 class="mt-0 mb-3 font-medium text-2xl text-900">Events</h2>
                <p class="mt-0 mb-0 font-normal text-base text-500">
                    View and manage all the events you have created
                </p>
            </div>
            <Button
                @click="createEventDialogVisible = true"
                v-ripple
                raised
                rounded
                class="mt-3"
                label="Create new event"
            ></Button>
        </header>
        <DataView
            :value="eventStore.events"
            paginator
            :rows="10"
            layout="list"
            :rowsPerPageOptions="[10, 25, 50]"
        >
            <template #list="{ items }">
                <article
                    v-for="(item, index) in items"
                    :key="index"
                    class="flex flex-column md:flex-row w-full gap-3 p-3 border-round border-1 surface-border mb-3"
                >
                    <div class="relative">
                        <img
                            :src="item.eventImage"
                            alt="Image"
                            class="border-round w-full h-full md:w-14rem md:h-14rem"
                        />
                        <p
                            class="absolute px-2 py-1 border-round-lg text-sm font-normal text-white mt-0 mb-0 bg-opacity-30"
                            style="
                                backdrop-filter: blur(10px);
                                top: 3%;
                                left: 3%;
                            "
                            :style="{ backgroundColor: item.statusColor }"
                        >
                            {{ item.status }}
                        </p>
                    </div>
                    <div class="flex flex-column w-full gap-3">
                        <div
                            class="flex w-full justify-content-between align-items-center"
                        >
                            <p class="font-semibold text-lg mt-0 mb-0 mr-3">
                                {{ item.name }}
                            </p>
                        </div>
                        <p class="font-normal text-lg text-600 mt-0 mb-0">
                            {{
                                item.description ??
                                'Edit your event to configure it'
                            }}
                        </p>
                        <div
                            class="flex flex-column md:flex-row flex-wrap md:justify-content-between mt-auto"
                        >
                            <!--                            <div class="flex gap-3">-->
                            <!--                                <p-->
                            <!--                                    class="text-base flex align-items-center text-900 mt-0 mb-1"-->
                            <!--                                >-->
                            <!--                                    <i class="pi pi-users mr-2"></i>-->
                            <!--                                    <span class="font-medium">10 Guest</span>-->
                            <!--                                </p>-->
                            <!--                                <p-->
                            <!--                                    class="text-base flex align-items-center text-900 mt-0 mb-1"-->
                            <!--                                >-->
                            <!--                                    <i class="pi pi-camera mr-2"></i>-->
                            <!--                                    <span class="font-medium"-->
                            <!--                                        >200 Photos Taken</span-->
                            <!--                                    >-->
                            <!--                                </p>-->
                            <!--                            </div>-->
                            <div class="flex align-items-center">
                                <router-link
                                    :to="{
                                        name: 'EventDetails',
                                        params: { eventId: item.eventId },
                                    }"
                                >
                                    <Button
                                        v-ripple
                                        class="m-2"
                                        label="View Event"
                                    ></Button>
                                </router-link>
                                <router-link
                                    :to="{
                                        name: 'EventEdit',
                                        params: { eventId: item.eventId },
                                    }"
                                >
                                    <Button
                                        v-ripple
                                        class="m-2"
                                        label="Edit Event"
                                    ></Button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </article>
            </template>
        </DataView>
    </section>
</template>

<style scoped></style>
