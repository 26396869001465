import { ResourcesConfig } from '@aws-amplify/core'

interface RuntimeConfiguration {
    graphql_endpoint: string
    api_gateway_endpoint: string
    aws_cognito_identity_pool_id: string
    aws_user_pools_id: string
    aws_user_pools_web_client_id: string
}

const loadRuntimeConfiguration = async (): Promise<RuntimeConfiguration> => {
    const resp = await fetch('/runtime_configuration.json')
    const value = await resp.json()

    return {
        api_gateway_endpoint: value.api_gateway_endpoint,
        graphql_endpoint: value.graphql_endpoint,
        aws_cognito_identity_pool_id: value.aws_cognito_identity_pool_id,
        aws_user_pools_id: value.aws_user_pools_id,
        aws_user_pools_web_client_id: value.aws_user_pools_web_client_id,
    }
}

let _runtimeConfiguration: RuntimeConfiguration | undefined = undefined

const getRuntimeConfiguration = async (): Promise<RuntimeConfiguration> => {
    if (_runtimeConfiguration) {
        return _runtimeConfiguration
    }

    return (_runtimeConfiguration = await loadRuntimeConfiguration())
}

const getAmplifyResourceConfig = async (): Promise<ResourcesConfig> => {
    const runtimeConfig = await getRuntimeConfiguration()

    return {
        API: {
            REST: {
                photoUpload: {
                    endpoint: runtimeConfig.api_gateway_endpoint,
                    region: 'eu-west-1',
                },
            },
            GraphQL: {
                endpoint: runtimeConfig.graphql_endpoint,
                region: 'eu-west-1',
                defaultAuthMode: 'userPool',
            },
        },
        Auth: {
            Cognito: {
                identityPoolId: runtimeConfig.aws_cognito_identity_pool_id,
                userPoolId: runtimeConfig.aws_user_pools_id,
                userPoolClientId: runtimeConfig.aws_user_pools_web_client_id,
                signUpVerificationMethod: undefined,
                mfa: {
                    status: 'off',
                    smsEnabled: false,
                    totpEnabled: false,
                },
                loginWith: {
                    email: true,
                    phone: false,
                    username: false,
                },
                userAttributes: {
                    email: { required: true },
                },
                passwordFormat: {
                    minLength: 8,
                    requireLowercase: true,
                    requireUppercase: true,
                    requireNumbers: true,
                    requireSpecialCharacters: true,
                },
                allowGuestAccess: true,
            },
        },
    }
}

export { getAmplifyResourceConfig, getRuntimeConfiguration }
