import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './components/App.vue'
import PrimeVue from 'primevue/config'
import StyleClass from 'primevue/styleclass'
import Ripple from 'primevue/ripple'
import router from './router'
import ToastService from 'primevue/toastservice'
import AmplifyVue from '@aws-amplify/ui-vue'
import ConfirmationService from 'primevue/confirmationservice'

import './index.css'
import './style.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import '@aws-amplify/ui-vue/styles.css'
import 'primevue/resources/themes/aura-light-amber/theme.css'
import { getAmplifyResourceConfig } from './configuration.ts'

import { Amplify } from 'aws-amplify'

getAmplifyResourceConfig().then((config) => {
    Amplify.configure(config)

    console.log('Amplify configured', Amplify.getConfig())

    createApp(App)
        .use(PrimeVue, { ripple: true })
        .use(router)
        .use(ToastService)
        .use(AmplifyVue)
        .use(createPinia())
        .use(ConfirmationService)
        .directive('styleclass', StyleClass)
        .directive('ripple', Ripple)
        .mount('#app')
})
