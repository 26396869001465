/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../api'
type GeneratedSubscription<InputType, OutputType> = string & {
    __generatedSubscriptionInput: InputType
    __generatedSubscriptionOutput: OutputType
}

export const onCreateEventPhoto =
    /* GraphQL */ `subscription OnCreateEventPhoto(
  $filter: ModelSubscriptionEventPhotoFilterInput
  $owner: String
) {
  onCreateEventPhoto(filter: $filter, owner: $owner) {
    id
    owner
    event {
      eventId
      name
      owner
      description
      eventStartDateTime
      eventEndDateTime
      imageBase64
      authenticationKey
      createdAt
      updatedAt
      __typename
    }
    eventId
    imagePath
    thumbnailPath
    height
    width
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
        APITypes.OnCreateEventPhotoSubscriptionVariables,
        APITypes.OnCreateEventPhotoSubscription
    >
export const onUpdateEventPhoto =
    /* GraphQL */ `subscription OnUpdateEventPhoto(
  $filter: ModelSubscriptionEventPhotoFilterInput
  $owner: String
) {
  onUpdateEventPhoto(filter: $filter, owner: $owner) {
    id
    owner
    event {
      eventId
      name
      owner
      description
      eventStartDateTime
      eventEndDateTime
      imageBase64
      authenticationKey
      createdAt
      updatedAt
      __typename
    }
    eventId
    imagePath
    thumbnailPath
    height
    width
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
        APITypes.OnUpdateEventPhotoSubscriptionVariables,
        APITypes.OnUpdateEventPhotoSubscription
    >
export const onDeleteEventPhoto =
    /* GraphQL */ `subscription OnDeleteEventPhoto(
  $filter: ModelSubscriptionEventPhotoFilterInput
  $owner: String
) {
  onDeleteEventPhoto(filter: $filter, owner: $owner) {
    id
    owner
    event {
      eventId
      name
      owner
      description
      eventStartDateTime
      eventEndDateTime
      imageBase64
      authenticationKey
      createdAt
      updatedAt
      __typename
    }
    eventId
    imagePath
    thumbnailPath
    height
    width
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
        APITypes.OnDeleteEventPhotoSubscriptionVariables,
        APITypes.OnDeleteEventPhotoSubscription
    >
export const onCreateEvent = /* GraphQL */ `subscription OnCreateEvent(
  $filter: ModelSubscriptionEventFilterInput
  $owner: String
) {
  onCreateEvent(filter: $filter, owner: $owner) {
    eventId
    name
    owner
    description
    eventStartDateTime
    eventEndDateTime
    imageBase64
    authenticationKey
    photos {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateEventSubscriptionVariables,
    APITypes.OnCreateEventSubscription
>
export const onUpdateEvent = /* GraphQL */ `subscription OnUpdateEvent(
  $filter: ModelSubscriptionEventFilterInput
  $owner: String
) {
  onUpdateEvent(filter: $filter, owner: $owner) {
    eventId
    name
    owner
    description
    eventStartDateTime
    eventEndDateTime
    imageBase64
    authenticationKey
    photos {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateEventSubscriptionVariables,
    APITypes.OnUpdateEventSubscription
>
export const onDeleteEvent = /* GraphQL */ `subscription OnDeleteEvent(
  $filter: ModelSubscriptionEventFilterInput
  $owner: String
) {
  onDeleteEvent(filter: $filter, owner: $owner) {
    eventId
    name
    owner
    description
    eventStartDateTime
    eventEndDateTime
    imageBase64
    authenticationKey
    photos {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteEventSubscriptionVariables,
    APITypes.OnDeleteEventSubscription
>
